/**
 */
var modules = modules || {}
modules.accessrulesDayViewManager = (function () {
  var instantiated,
    venue_name,
    media_url,
    id,
    payment_setup,
    tax_rate,
    tax_groups,
    currency_symbol,
    audience_hierarchy,
    venue_policy,
    venue_cancellation_policy,
    can_save_card,
    tag_groups,
    experiences,
    venueSettings,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id,
    rules,
    seatingarea_tables
  var upsells = {}
  var upsellsToInventory = {}
  var helpers = {
    shiftOrder: {
      DAY: 1,
      BREAKFAST: 2,
      BRUNCH: 3,
      LUNCH: 4,
      DINNER: 5,
      LEGACY: 6,
    },

    getSelectedDays: function (dayOfWeek) {
      var customDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      return modules.weekViewManager.getSelectedDays(dayOfWeek, customDays)
    },

    ListView: React.createClass({
      componentWillMount: function () {
        this.onDateUpdate(this.props.date)
        this.forceUpdate()
      },

      getDefaultProps: function () {
        return {
          accessRules: [],
        }
      },

      onDateUpdate: function (date) {
        this.props.fetchData(this.props.venueName, date, this.postDataUpdate)
      },

      postDataUpdate: function (date, accessRules, shifts) {
        this.props.date = date
        this.props.accessRules = accessRules
        this.props.shifts = shifts
        this.setState({ selectedDate: date })
      },

      render: function () {
        var listViewStyle = {
          margin: '10px',
        }

        var cellStyle = {
          borderWidth: '0px',
          paddingLeft: '15px',
          paddingRight: '10px',
          textAlign: 'left',
          color: '#212A36',
          lineHeight: '16px',
        }

        var listHeader = <helpers.ListHeader cellStyle={cellStyle} />

        var numAccessRules = _.size(this.props.accessRules)

        var listRows = _.map(
          this.props.accessRules,
          function (accessRule, idx) {
            var isLastRule = idx === numAccessRules - 1
            return (
              <helpers.ListRow
                cellStyle={cellStyle}
                accessRule={accessRule}
                ruleDay={this.state.selectedDate}
                isLastRule={isLastRule}
                viewSlide={this.props.viewSlide}
              />
            )
          },
          this
        )

        return (
          <div>
            <helpers.Toolbar
              onDateUpdate={this.onDateUpdate}
              addSlide={this.props.addSlide}
              shifts={this.props.shifts}
              date={this.props.date}
            />
            <table className="access-rules-list" style={listViewStyle}>
              {listHeader}
              <tbody>{listRows}</tbody>
            </table>
          </div>
        )
      },
    }),

    Toolbar: React.createClass({
      componentDidUpdate: function () {
        const elem = this.getDOMNode().querySelector('.list-view-action-buttons')
        window.SvrManager.MgrAccessRules.renderListViewActionButtons(
          elem,
          this.props.date,
          this.props.shifts.map(shift => ({
            category: shift.category,
            startTimeDisplay: shift.start_time_display,
            endTimeDisplay: shift.end_time_display,
          })),
          modules.accessslideout.shiftCategoryOptions,
          this.props.addSlide
        )
      },

      render: function () {
        var toolbarStyle = {
          margin: '20px 10px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }

        var leftToolbarStyle = {
          display: 'flex',
          flexDirection: 'row',
        }

        return (
          <div style={toolbarStyle}>
            <div style={leftToolbarStyle}>
              <helpers.DatePicker onDateUpdate={this.props.onDateUpdate} date={this.props.date} />
              <helpers.WeekViewButton />
            </div>
            <div className="list-view-action-buttons"></div>
          </div>
        )
      },
    }),

    DatePicker: React.createClass({
      shouldComponentUpdate: function () {
        return false
      },

      componentDidMount: function () {
        var onDateUpdate = this.props.onDateUpdate

        $('#access-date-picker').datepicker({
          showButtonPanel: true,
          dateFormat: 'D, MM d yy',

          beforeShow: function () {
            $('#ui-datepicker-div').addClass('customize')
          },

          onSelect: function () {
            var dateSelected = $(this).datepicker('getDate')
            var formattedDate = $.datepicker.formatDate('mm-dd-yy', dateSelected)
            onDateUpdate(formattedDate)
          },

          onRefreshAfterAdd: function (formattedDate) {
            onDateUpdate(formattedDate)
          },
        })
      },

      render: function () {
        var datePickerStyle = {
          cursor: 'pointer',
          border: '1px solid #212a36',
          height: '40px',
          width: '176px',
          borderRadius: '5px',
          paddingLeft: '8px',
          fontSize: '14px',
          color: '#212a36',
          background: '#fff url(' + helpers.media_url + 'images/new-calendar-icon.png)',
          backgroundSize: '18px',
          backgroundPosition: 'right 8px top 11px',
          backgroundRepeat: 'no-repeat',
        }

        var initialDateSelected = new Date(this.props.date.replace(/-/g, '/'))
        var initialFormattedDate = $.datepicker.formatDate('D, MM d yy', initialDateSelected)

        return <input readOnly="true" value={initialFormattedDate} id="access-date-picker" style={datePickerStyle} />
      },
    }),

    WeekViewButton: React.createClass({
      componentDidMount: function () {
        const elem = this.getDOMNode()
        window.SvrManager.MgrAccessRules.renderSwitchToWeekViewButton(elem)
      },

      render: function () {
        return <div className="week-view-button"></div>
      },
    }),

    ListHeader: React.createClass({
      render: function () {
        var { cellStyle } = this.props

        var headerStyle = {
          backgroundColor: '#e8e9ea',
          height: '30px',
        }

        var firstCellStyle = {
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '5px',
        }

        var lastCellStyle = {
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        }

        return (
          <tr className="access-rules-list-header" style={headerStyle}>
            <th style={{ ...cellStyle, width: '126px', ...firstCellStyle }}>Rule</th>
            <th style={{ ...cellStyle, width: '126px' }}>Schedule</th>
            <th style={{ ...cellStyle, width: '126px' }}>Time</th>
            <th style={{ ...cellStyle, width: '65px' }}>Party Size</th>
            <th style={{ ...cellStyle, width: '100px' }}>Slot Description</th>
            <th style={{ ...cellStyle, width: '104px' }}>Credit Card</th>
            <th style={{ ...cellStyle, width: '80px' }}>Offer</th>
            <th style={{ ...cellStyle, width: '80px' }}>Upgrades</th>
            <th style={{ ...cellStyle, width: '80px' }}>Audience</th>
            <th style={{ ...cellStyle, width: '80px' }}>Key Overrides</th>
            <th style={{ ...cellStyle, width: '126px', ...lastCellStyle }}></th>
          </tr>
        )
      },
    }),

    ListRow: React.createClass({
      onClickHandler: function (e) {
        var target = $(e.target).closest('tr')
        this.props.viewSlide(target)
      },

      render: function () {
        var { accessRule, ruleDay, isLastRule } = this.props

        var cellStyle = isLastRule
          ? {
              ...this.props.cellStyle,
              paddingTop: '20px',
              paddingBottom: '20px',
              borderBottom: '1px solid white', // Need this border to override production CSS
            }
          : {
              ...this.props.cellStyle,
              paddingTop: '20px',
              paddingBottom: '20px',
              borderBottom: '1px solid #e8e9ea',
            }

        var rowStyle = {
          cursor: 'pointer',
          verticalAlign: 'text-top',
        }

        var ruleNameDisplay = <b>{accessRule.name}</b>

        // Schedule column
        var shiftCategories = modules.accessrulesShared.build_shift_categories_display(
          accessRule.access_time_type,
          accessRule.restrict_to_shifts || !!accessRule.shift_category,
          accessRule.shift_categories
        )
        var daysOfWeek = helpers.getSelectedDays(accessRule.day_of_week).join(', ')
        var startDate = accessRule.is_override ? accessRule.date : accessRule.start_date
        var startDateFormatted = modules.accessslideout.convertToJSDate(startDate)
        var endDate = accessRule.is_override ? accessRule.date : accessRule.end_date
        var endDateFormatted = endDate ? modules.accessslideout.convertToJSDate(endDate) : undefined
        var dateRange = startDateFormatted + ' - ' + (endDateFormatted ? endDateFormatted : 'Indefinite')
        var scheduleDisplay = (
          <div>
            <b>{shiftCategories}</b>
            <p>{daysOfWeek}</p>
            <br />
            <i>{dateRange}</i>
          </div>
        )

        // Time column
        var MAX_TIME_SLOTS = 5
        var startTime = Pmp.Utils.timeWithLocale(accessRule.start_time_display)
        var endTime = Pmp.Utils.timeWithLocale(accessRule.end_time_display)
        var specificTimes = Pmp.Manager.Global._is_military_time
          ? _.map(accessRule.specific_times_display, Pmp.Utils.toMilitaryTime)
          : accessRule.specific_times_display
        var specificTimesAdditional = ''
        if (specificTimes.length > MAX_TIME_SLOTS) {
          specificTimes = specificTimes.slice(0, MAX_TIME_SLOTS)
          specificTimesAdditional = ', more'
        }
        var specificTimesDisplay = specificTimes.join(', ') + specificTimesAdditional
        var timeDisplay =
          accessRule.access_time_type === 'SPECIFIC'
            ? specificTimesDisplay
            : accessRule.access_time_type === 'TIME_RANGE'
            ? startTime + ' - ' + endTime
            : modules.accessrulesShared.build_value_by_shift_times_display(
                accessRule.start_end_times_by_shift_display,
                accessRule.shift_categories
              )

        // Party Size column
        var partySizeDisplay =
          accessRule.party_size_min && accessRule.party_size_max
            ? accessRule.party_size_min + ' - ' + accessRule.party_size_max
            : 'Follow Shift'

        // Slot Description column
        var slotDescriptionDisplay = _.isEmpty(accessRule.public_time_slot_description) ? '--' : accessRule.public_time_slot_description

        // Credit Card column
        if (accessRule.use_shift_payment_and_policy) {
          var creditCardDisplay = 'Follow Shift'
        } else if (accessRule.require_credit_card) {
          var creditCardDisplay = 'CC required'
        } else {
          var creditCardDisplay = 'No CC required'
        }

        const getOfferName = (experienceId) => {
          if (!experienceId || !experiences || !Array.isArray(experiences)) {
            return '--'
          }
          const experience = experiences.find((exp) => exp?.id === experienceId)
          return experience?.name
            ? (experience.offer_type === 'PDR' ? `Space: ${experience.name}` : experience.name)
            : '--'
        }
        // Offer column
        var offerDisplay = getOfferName(accessRule.experience_id)

        // Upgrades column
        if (accessRule.is_using_shift_upsells) {
          var upgradesDisplay = 'Follow Shift'
        } else if (_.isEmpty(accessRule.upsell_categories)) {
          var upgradesDisplay = '--'
        } else {
          var upgradesDisplay = 'Yes'
        }

        // Audience column

        var all_tiers = []
        var MAX_PER_TIER = 3
        var audience_name_lookup = modules.accessrulesShared.build_audience_name_lookup(helpers.audience_hierarchy)

        for (i = 0; i < accessRule.audience_tiers.length; i++) {
          var tier = accessRule.audience_tiers[i]
          var total_audience_count = tier.channels.length + tier.third_parties.length + tier.concierges.length
          var audience_builder = {
            audience_count: 0,
            names: '',
          }
          var append_audience_name = function (audience_name) {
            if (audience_name) {
              audience_builder.names += audience_name
              audience_builder.audience_count++
              if (audience_builder.audience_count < total_audience_count) {
                audience_builder.names += ', '
              }
            }
          }
          var append_audience_name_for_tier_list = function (tier_list) {
            for (j = 0; j < tier_list.length && audience_builder.audience_count < MAX_PER_TIER; j++) {
              append_audience_name(audience_name_lookup[tier_list[j]])
            }
          }
          append_audience_name_for_tier_list(tier.channels)
          append_audience_name_for_tier_list(tier.third_parties)
          append_audience_name_for_tier_list(tier.concierges)

          if (audience_builder.audience_count === MAX_PER_TIER && total_audience_count > audience_builder.audience_count) {
            var remaining = total_audience_count - audience_builder.audience_count
            audience_builder.names += '+' + remaining.toString() + ' more'
          }

          if (audience_builder.names.length > 0) {
            all_tiers.push(audience_builder.names)
          }
        }

        var audience_out = []

        var tier_heading_style = {
          color: '#999',
        }

        for (i = 0; i < all_tiers.length; i++) {
          var title_div = <div style={tier_heading_style}>Tier {(i + 1).toString()}</div>
          audience_out.push(title_div)
          var audiences_div = <div>{all_tiers[i]}</div>
          audience_out.push(audiences_div)
        }

        //Key overrides column
        var overrides_out = []
        if (accessRule.default_pacing !== null) {
          overrides_out.push(<div>Pacing</div>)
        }
        if (accessRule.duration_min !== null || accessRule.duration_max !== null) {
          overrides_out.push(<div>Duration</div>)
        }
        if (accessRule.policy !== null) {
          overrides_out.push(<div>Booking Policy</div>)
        }
        if (accessRule.cancellation_policy_type === 'custom') {
          overrides_out.push(<div>Cancellation</div>)
        }
        if (accessRule.cutoff_type !== null) {
          overrides_out.push(<div>Cut-off time</div>)
        }

        // View More column
        var viewMoreDisplay = (
          <div>
            <i style={{ color: '#888C92' }}>Click to view more</i>
          </div>
        )

        // Row must have unique React key so data-id and data-day will update on date change
        var rowKey = accessRule.id + '-' + ruleDay

        return (
          <tr
            className="access-rules-list-row"
            key={rowKey}
            data-id={accessRule.id}
            data-day={ruleDay}
            onClick={this.onClickHandler}
            style={rowStyle}
          >
            <td style={cellStyle}>{ruleNameDisplay}</td>
            <td style={cellStyle}>{scheduleDisplay}</td>
            <td style={cellStyle}>{timeDisplay}</td>
            <td style={cellStyle}>{partySizeDisplay}</td>
            <td style={cellStyle}>{slotDescriptionDisplay}</td>
            <td style={cellStyle}>{creditCardDisplay}</td>
            <td style={cellStyle}>{offerDisplay}</td>
            <td style={cellStyle}>{upgradesDisplay}</td>
            <td style={cellStyle}>{audience_out}</td>
            <td style={cellStyle}>{overrides_out}</td>
            <td style={cellStyle}>{viewMoreDisplay}</td>
          </tr>
        )
      },
    }),

    generateList: function (id, venue_name, audience_hierarchy, media_url, date, viewSlide, addSlide, fetchData, venueSettings) {
      helpers.id = id
      helpers.venue_name = venue_name
      helpers.audience_hierarchy = audience_hierarchy
      helpers.media_url = media_url
      React.render(
        <helpers.ListView
          fetchData={fetchData}
          venueName={venue_name}
          date={date}
          viewSlide={viewSlide}
          addSlide={addSlide}
          venueSettings={venueSettings}
        />,
        document.getElementById(id)
      )
    },
  }

  var viewSlide = function (target) {
    var currentRule = $(target),
      ruleId = $(currentRule).data('id'),
      selectedRule = _.findWhere(_.flatten(_.values(rules)), { id: ruleId }),
      rule = _.extend({}, selectedRule, {
        selectedDay: $(currentRule).data('day'),
        id: $(currentRule).data('id'),
      })

    $.when(
      $.ajax({
        url: '/api-yoa/booking_access/' + rule.id + '?date=' + rule.selectedDay + '&venue=' + venue_name,
      }),
      seatingarea_tables
    ).then(function (response1, response2) {
      var responseBody = response1 ? _.first(response1) : undefined,
        rule_data = responseBody ? responseBody['data'] : undefined
      if (responseBody && responseBody.status != '200') {
        UserNotificationInterop.error(responseBody.msg)
      } else {
        modules.accessslideout.view(
          'flyout-form',
          helpers.audience_hierarchy,
          venue_name,
          media_url,
          rule_data.shift_categories,
          response2,
          rule.selectedDay,
          _.partial(helpers.onAddOverlay, rule.id),
          _.partial(onCloseSlideout, id),
          payment_setup,
          tax_rate,
          tax_groups,
          can_save_card,
          currency_symbol,
          venue_policy,
          venue_cancellation_policy,
          upsells,
          upsellsToInventory,
          experiences,
          venueSettings,
          rule_data,
          tag_groups,
          'DAYVIEW',
          venue_default_booking_policy_id,
          venue_default_cancellation_policy_id
        )
      }
    })
  }

  var addSlide = function (shift, date) {
    var ruleName
    var category = shift?.category ?? null
    var selectedDay = date
    var startTime = shift?.startTimeDisplay ?? null
    var endTime = shift?.endTimeDisplay ?? null
    var existing_rule = {}

    $.when(seatingarea_tables).then(function (response) {
      modules.accessslideout.add(
        'flyout-form',
        helpers.audience_hierarchy,
        venue_name,
        media_url,
        response,
        ruleName,
        [category],
        selectedDay,
        startTime,
        endTime,
        _.partial(helpers.onAddOverlay, id),
        _.partial(onCloseSlideout, id),
        venue_policy,
        venue_cancellation_policy,
        payment_setup,
        tax_rate,
        tax_groups,
        can_save_card,
        currency_symbol,
        venue_policy,
        venue_cancellation_policy,
        upsells,
        upsellsToInventory,
        experiences,
        venueSettings,
        existing_rule,
        tag_groups,
        'DAYVIEW',
        venue_default_booking_policy_id,
        venue_default_cancellation_policy_id,
        window.globalInit.venueSettings.is_thefork_integration_enabled,
        window.globalInit.venueSettings.is_google_booking_enabled
      )
    })
  }

  var fetchData = function (venue_name, date, postDataUpdate) {
    var post_data_update_caller = function (response1, response2) {
      var parsedDate = date.replace(/-/g, '/')
      var accessRulesRaw = response1.data.access[parsedDate]
      // Sort access rules by shift category, then by start time
      accessRulesRaw = accessRulesRaw.sort(function (a, b) {
        var shiftOrderA = helpers.shiftOrder[a.shift_category]
        var shiftOrderB = helpers.shiftOrder[b.shift_category]
        if (shiftOrderA < shiftOrderB) {
          return -1
        } else if (shiftOrderA === shiftOrderB) {
          var startTimeA = a.start_time
          var startTimeB = b.start_time
          return new Date('1970/01/01 ' + startTimeA) - new Date('1970/01/01 ' + startTimeB)
        } else {
          return 1
        }
      })

      var ids = new Set()
      var accessRules = []

      //Dedupe access rules, since specified timeslots for a single rule come back from the API as individual rules
      for (var i = 0; i < accessRulesRaw.length; i++) {
        if (!ids.has(accessRulesRaw[i].id)) {
          ids.add(accessRulesRaw[i].id)
          accessRules.push(accessRulesRaw[i])
        }
      }

      var shifts = response2.data.shifts[parsedDate]
      postDataUpdate(parsedDate, accessRules, shifts)
    }

    modules.accessrulesShared.fetch_data(venue_name, date, post_data_update_caller)
  }

  var fetchUpsells = function (venue_name) {
    modules.accessrulesShared.fetch_upsells(venue_name, upsells, upsellsToInventory)
  }

  var fetchExperiences = function (venue_name) {
    var on_success = function (result) {
      experiences = result
    }
    modules.accessrulesShared.fetch_experiences(venue_name, experiences, on_success)
  }

  var onCloseSlideout = function (id) {
    $('#' + id)
      .find('.ui-resizable')
      .remove()
  }

  var populateSeatingAreaData = function () {
    seatingarea_tables = $.Deferred()
    $.ajax({
      url: '/manager/' + venue_name + '/data/seatingareastables',
      success: _.bind(function (response) {
        seatingarea_tables.resolve(response['payload']['content'])
      }, this),
    })
  }

  var refresh = function (date) {
    var date_parts = date.split('/')
    var reformatted = date_parts[0] + '-' + date_parts[1] + '-' + date_parts[2]
    $('#access-date-picker').data('datepicker').settings.onRefreshAfterAdd(reformatted)
  }

  var resetResizableOverlays = function (startTime, endTime) {}

  var renderInstance = function (date) {
    if (!instantiated) {
      console.debug('not instantiated')
      return
    }
    venueSettings = window.globalInit.venueSettings
    if (venueSettings.is_sizzle_enabled) {
      fetchExperiences(venue_name)
    }
    fetchUpsells(venue_name)
    helpers.generateList(id, venue_name, audience_hierarchy, media_url, date, viewSlide, addSlide, fetchData, venueSettings)
    populateSeatingAreaData()
  }

  var init = function (
    domId,
    content_audience_hierarchy,
    name,
    url,
    date,
    _payment_setup,
    _tax_rate,
    _tax_groups,
    _currency_symbol,
    _start_of_day_hour,
    _can_save_card,
    _tag_groups
  ) {
    instantiated = true
    venue_name = Pmp.Manager.Global._url_key_or_id
    media_url = url
    id = domId
    payment_setup = _payment_setup
    tax_rate = _tax_rate
    tax_groups = _tax_groups
    currency_symbol = _currency_symbol
    can_save_card = _can_save_card
    audience_hierarchy = content_audience_hierarchy
    tag_groups = _tag_groups
    var dateParam = window.location.search.replace('?d=', '')
    date = !_.isEmpty(dateParam) ? atob(dateParam) : new Date().format('mm-dd-yyyy')

    $.ajax({ url: '/booking/venue_info', data: { venue: name } }).then(
      response => {
        venue_default_booking_policy_id = response.booking_policy_id
        venue_default_cancellation_policy_id = response.cancellation_policy_id
        renderInstance(date)
      },
      error => {
        Interface._alert('Unknown error happened. Please try later.')
      }
    )
  }

  return {
    render: renderInstance,
    resetResizableOverlays: resetResizableOverlays,
    refresh: refresh,
    init: init,
  }
})()
